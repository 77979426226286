"use client";

import CaseUnitInput from "@/components/features/cart-edit/case-unit-input";
import DailySalesDisplay from "@/components/shared/product/daily-sales-display";
import DOIDisplay from "@/components/shared/product/doi-display";
import RetailerInventoryDisplay from "@/components/shared/product/retailer-inventory";
import { ShopCartTransaction } from "@/lib/cart/cart-types";
import { TARGET_DOI_FILTER_ID } from "@/lib/shop/shop-constants";
import { ShopRetailerInventory } from "@/lib/shop/shop-types";
import { useCartStore } from "@/state-management/stores/cart-store";
import { ShopStore } from "@/state-management/stores/shop-store";
import { PlusCircle } from "lucide-react";
import { memo, useCallback, useEffect, useRef, useState } from "react";

interface AddToCartFooterProps {
    productID: number;
    wholesaleUnitsPerCase: number;
    sellByCaseOnly: boolean;
    retailerInventory: ShopRetailerInventory | null;
    minOrderUnits: number;
}

const MemoizedCaseUnitInput = memo(function MemoizedCaseUnitInput({
    productID,
    wholesaleUnitsPerCase,
    sellByCaseOnly,
    initialCases,
    initialUnits,
    minOrderUnits
}: {
    productID: number;
    wholesaleUnitsPerCase: number;
    sellByCaseOnly: boolean;
    initialCases: number;
    initialUnits: number;
    minOrderUnits: number;
}) {
    return (
        <CaseUnitInput
            productID={productID}
            wholesaleUnitsPerCase={wholesaleUnitsPerCase}
            sellByCaseOnly={sellByCaseOnly}
            promotionID={null}
            initialQuantity={{
                cases: initialCases,
                units: initialUnits
            }}
            minOrderUnits={minOrderUnits}
        />
    );
});

const AddToCartButton = memo(function AddToCartButton() {
    return (
        <>
            <PlusCircle className="h-4 w-4" />
            Add to Cart
        </>
    );
});

const CartQuantityButton = memo(function CartQuantityButton({ units }: { units: number }) {
    return (
        <span className="flex items-center gap-1">
            <span className="font-large">{units}</span>
            <span className="font-medium">Unit{units > 1 ? "s" : ""} in cart</span>
        </span>
    );
});

export default function AddToCartFooter({
    productID,
    wholesaleUnitsPerCase,
    sellByCaseOnly,
    retailerInventory,
    minOrderUnits
}: AddToCartFooterProps) {
    const [isQuickAddOpen, setIsQuickAddOpen] = useState(false);
    const [baseTransaction, setBaseTransaction] = useState<ShopCartTransaction | undefined>();
    const [currentUnits, setCurrentUnits] = useState(0);
    const [targetDOI, setTargetDOI] = useState<number | undefined>(undefined);
    const getFilter = ShopStore.use.getFilter();

    const quickAddRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const targetDOI = getFilter(TARGET_DOI_FILTER_ID)
            ? Number(getFilter(TARGET_DOI_FILTER_ID) as string)
            : undefined;
        if (targetDOI && targetDOI > 0) {
            setTargetDOI(targetDOI);
        } else {
            setTargetDOI(undefined);
        }
    }, [getFilter, setTargetDOI]);

    const blurInputsAndClose = useCallback(() => {
        const inputs = quickAddRef.current?.querySelectorAll("input");
        const activeElement = document.activeElement;

        if (inputs && activeElement && activeElement instanceof HTMLElement) {
            inputs.forEach((input) => {
                if (input === activeElement) {
                    input.blur();
                }
            });
        }

        setIsQuickAddOpen(false);
    }, []);

    const handleButtonClick = useCallback(
        (e: React.MouseEvent) => {
            e.preventDefault();
            if (isQuickAddOpen) {
                blurInputsAndClose();
            } else {
                setIsQuickAddOpen(true);
            }
        },
        [isQuickAddOpen, blurInputsAndClose]
    );

    const handleClickOutside = useCallback(
        (event: MouseEvent) => {
            if (isQuickAddOpen) {
                event.preventDefault();
                event.stopImmediatePropagation();

                if (quickAddRef.current && !quickAddRef.current.contains(event.target as Node)) {
                    blurInputsAndClose();
                }
            }
        },
        [isQuickAddOpen, blurInputsAndClose]
    );

    useEffect(() => {
        const unsubscribe = useCartStore
            .getState()
            .subscribeToProduct(productID, (transactions) => {
                if (transactions && transactions.length > 0) {
                    let currentUnits = 0;
                    transactions.forEach((transaction) => {
                        if (transaction.manualPromotionID === null) {
                            setBaseTransaction(transaction);
                        }
                        currentUnits += transaction.numUnits;
                    });

                    setCurrentUnits(currentUnits);
                } else {
                    setBaseTransaction(undefined);
                    setCurrentUnits(0);
                }
            });

        return () => {
            unsubscribe();
        };
    }, [productID]);

    useEffect(() => {
        if (isQuickAddOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isQuickAddOpen, handleClickOutside]);

    const initialCases = baseTransaction
        ? Math.floor(baseTransaction.numUnits / wholesaleUnitsPerCase)
        : 0;
    const initialUnits = baseTransaction ? baseTransaction.numUnits % wholesaleUnitsPerCase : 0;

    return (
        <div className="flex w-full flex-col gap-1">
            {retailerInventory ? (
                <div className="flex w-full items-center justify-between gap-2 px-2">
                    <div className="flex w-full flex-col ">
                        <div className="flex w-full items-center justify-between gap-2">
                            <RetailerInventoryDisplay
                                inventory={retailerInventory.inventory}
                                lastInventoryDate={retailerInventory.lastInventoryDate}
                            />
                            <DOIDisplay
                                inventory={retailerInventory.inventory}
                                dailySales={retailerInventory.dailySales}
                                currentDOI={retailerInventory.DOI}
                                currentCartUnits={currentUnits}
                                productID={productID}
                                wholesaleUnitsPerCase={wholesaleUnitsPerCase}
                                targetDOI={targetDOI}
                                className="text-sm"
                            />
                        </div>
                        <div className="h-fit w-full items-center p-0 text-center">
                            <DailySalesDisplay
                                dailySales={retailerInventory.dailySales}
                                className="text-sm"
                            />
                        </div>
                    </div>
                </div>
            ) : (
                <div className="m-auto w-full text-center align-middle text-sm text-gray-400">
                    No POS Data
                </div>
            )}
            <div className="group/add relative w-full" onMouseLeave={blurInputsAndClose}>
                <button
                    className={`flex w-full items-center justify-center gap-2 border-t ${
                        currentUnits ? "bg-cart text-white" : ""
                    } px-4 py-3 text-sm font-medium text-gray-600`}
                    onClick={handleButtonClick}
                >
                    {currentUnits ? (
                        <CartQuantityButton units={currentUnits} />
                    ) : (
                        <AddToCartButton />
                    )}
                </button>
                <div
                    ref={quickAddRef}
                    className={`absolute bottom-0 left-0 right-0 transform transition-all duration-300 ease-in-out
                    ${isQuickAddOpen ? "visible translate-y-0 " : "invisible translate-y-full"}
                    group-hover/add:visible group-hover/add:translate-y-0 `}
                >
                    <div
                        className={`border-t bg-white px-4 ${minOrderUnits === 1 ? "py-4" : "pb-4"} shadow-lg`}
                    >
                        <MemoizedCaseUnitInput
                            key={`${productID}-${currentUnits}`}
                            productID={productID}
                            wholesaleUnitsPerCase={wholesaleUnitsPerCase}
                            sellByCaseOnly={sellByCaseOnly}
                            initialCases={initialCases}
                            initialUnits={initialUnits}
                            minOrderUnits={minOrderUnits}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
