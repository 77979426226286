import { Blend, ShopFilters, ShopLayout, ShopSort, ShopSortOption } from "./shop-types";

// TODO: Can we make this array from Blend type instead of hard coding array values?
export const VALID_BLENDS: Blend[] = [
    "Indica Hybrid",
    "Hybrid",
    "1:1 CBD",
    "Sativa Hybrid",
    "High CBD",
    "Sativa",
    "Indica"
];

export const GRID_CONFIG = {
    base: "grid-cols-1",
    sm: "sm:grid-cols-2",
    md: "md:grid-cols-2",
    lg: "lg:grid-cols-3",
    xl: "xl:grid-cols-4",
    "2xl": "2xl:grid-cols-5",
    "3xl": "3xl:grid-cols-6",
    "4xl": "4xl:grid-cols-7"
};

export const RESULTS_PER_PAGE = 40;

// layout
export const DEFAULT_LAYOUT: ShopLayout = "grid";
export const LAYOUT_PARAMETER_NAME = "layout";

// filters
export const DEFAULT_FILTERS: ShopFilters = {};
export const CATEGORY_FILTER_ID = "category";
export const SUPPLIER_FILTER_ID = "brands";
export const TARGET_DOI_FILTER_ID = "target-doi";
export const SHOW_BELOW_ONLY_FILTER_ID = "show-below-only";
export const SHOW_MY_PRODUCTS_ONLY_FILTER_ID = "show-my-products-only";
export const INCLUDE_SAMPLES_FILTER_ID = "include-samples";
export const QUERY_STRING_FILTER_ID = "query-string";
export const BLENDS_FILTER_ID = "blends";
export const INCLUDE_OUT_OF_STOCK_FILTER_ID = "include-out-of-stock";
export const UNIT_PRICE_FILTER_ID = "unit-price";
export const CASE_QUANTITY_FILTER_ID = "case-quantity";
export const IS_NEW_FILTER_ID = "is-new";
export const IS_ON_SALE_FILTER_ID = "is-on-sale";
export const UNITS_PER_CASE_FILTER_ID = "units-per-case";

export const VALID_FILTER_KEYS = new Set([
    CATEGORY_FILTER_ID,
    SUPPLIER_FILTER_ID,
    TARGET_DOI_FILTER_ID,
    SHOW_BELOW_ONLY_FILTER_ID,
    SHOW_MY_PRODUCTS_ONLY_FILTER_ID,
    INCLUDE_SAMPLES_FILTER_ID,
    QUERY_STRING_FILTER_ID,
    BLENDS_FILTER_ID,
    INCLUDE_OUT_OF_STOCK_FILTER_ID,
    UNITS_PER_CASE_FILTER_ID,
    UNIT_PRICE_FILTER_ID,
    IS_NEW_FILTER_ID,
    IS_ON_SALE_FILTER_ID
]);

// sort
export const SORT_PARAMETER_NAME = "sortKey";
export const SORT_REVERSE_PARAMETER_NAME = "reverse";

export const DEFAULT_SORT: ShopSort = {
    sortKey: "supplier",
    type: "string",
    reverse: false
};
export const SORT_OPTIONS_AUTHENTICATED: ShopSortOption[] = [
    {
        label: "Unit Price",
        type: "number",
        sortKey: "unit-price"
    },

    {
        label: "Current DOI",
        type: "number",
        sortKey: "current-doi"
    },

    {
        label: "Daily Sales",
        type: "number",
        sortKey: "daily-sales"
    }
];

export const SORT_OPTIONS_PUBLIC: ShopSortOption[] = [
    { label: "Brand", type: "string", sortKey: "supplier" },
    { label: "Category", type: "string", sortKey: "category" }
];
