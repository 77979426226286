/* eslint-disable no-unused-vars */
"use client";

import {
    DEFAULT_LAYOUT,
    DEFAULT_SORT,
    LAYOUT_PARAMETER_NAME,
    SORT_PARAMETER_NAME,
    SORT_REVERSE_PARAMETER_NAME,
    VALID_FILTER_KEYS
} from "@/lib/shop/shop-constants";
import { FilterValue, ShopFilters, ShopLayout, ShopSort } from "@/lib/shop/shop-types";
import { useRouter } from "next/navigation";
import { create } from "zustand";
import { createSelectors } from "./store-index";

type NextAppRouter = ReturnType<typeof useRouter>;

export interface ShopStoreState {
    filters: ShopFilters;
    sort: ShopSort;
    layout: ShopLayout;
    setFilters: (filters: ShopFilters, router?: NextAppRouter) => void;
    clearAllFilters: (router?: NextAppRouter) => void;
    getFilter: <T extends FilterValue>(key: string) => T | null;
    setFilter: (key: string, value: FilterValue, router?: NextAppRouter) => void;
    removeFilter: (key: string, router?: NextAppRouter) => void;
    setSort: (sort: ShopSort, router?: NextAppRouter) => void;
    setLayout: (layout: ShopLayout, router?: NextAppRouter) => void;
    updateURLFromState: (router: NextAppRouter) => void;
}

// Helper function to convert filter value to URL string
const filterValueToString = (value: FilterValue): string | null => {
    if (value === null || value === undefined) return null;
    if (Array.isArray(value)) return value.join(",");
    if (typeof value === "boolean") return value ? "true" : null;
    return value.toString();
};

export const ShopStore = createSelectors(
    create<ShopStoreState>()((set, get) => {
        return {
            filters: {},
            sort: DEFAULT_SORT,
            layout: DEFAULT_LAYOUT,

            setFilters: (filters: ShopFilters, router?: NextAppRouter) => {
                // Check for any filter parameters that aren't in our valid set from the current URL
                Object.keys(filters).forEach((filter) => {
                    if (!VALID_FILTER_KEYS.has(filter)) delete filters[filter];
                });
                set(() => ({ filters }));
                if (router) get().updateURLFromState(router);
            },

            clearAllFilters: (router?: NextAppRouter) => {
                set(() => ({ filters: {} }));
                if (router) get().updateURLFromState(router);
            },

            getFilter: <T extends FilterValue>(key: string): T | null => {
                const state = get();
                return (state.filters[key] as T) || null;
            },

            setFilter: (key: string, value: FilterValue, router?: NextAppRouter) => {
                if (VALID_FILTER_KEYS.has(key)) {
                    set((state) => ({
                        filters: {
                            ...state.filters,
                            [key]: value
                        }
                    }));
                    if (router) get().updateURLFromState(router);
                }
            },

            removeFilter: (key: string, router?: NextAppRouter) => {
                set((state) => {
                    const newFilters = { ...state.filters };
                    delete newFilters[key];
                    return { filters: newFilters };
                });
                if (router) get().updateURLFromState(router);
            },

            setSort: (sort: ShopSort, router?: NextAppRouter) => {
                set({ sort });
                if (router) get().updateURLFromState(router);
            },

            setLayout: (layout: ShopLayout, router?: NextAppRouter) => {
                set({ layout });
                if (router) get().updateURLFromState(router);
            },

            updateURLFromState: (router: NextAppRouter) => {
                const state = get();

                // Create URL from current state filters
                const stateSearchParams = new URLSearchParams();
                Object.entries(state.filters).forEach(([filter, filterValue]) => {
                    if (VALID_FILTER_KEYS.has(filter)) {
                        const stringValue = filterValueToString(filterValue);
                        if (stringValue && stringValue !== "" && stringValue !== "false") {
                            stateSearchParams.set(filter, stringValue);
                        }
                    }
                });

                // Add layout parameter
                if (state.layout !== DEFAULT_LAYOUT) {
                    stateSearchParams.set(LAYOUT_PARAMETER_NAME, state.layout);
                }

                // Add sort parameters
                if (state.sort.sortKey !== "supplier") {
                    stateSearchParams.set(SORT_PARAMETER_NAME, state.sort.sortKey);
                }

                if (state.sort.reverse) {
                    stateSearchParams.set(SORT_REVERSE_PARAMETER_NAME, "true");
                }

                stateSearchParams.sort();

                const urlSearchParams = new URLSearchParams(window.location.search);
                urlSearchParams.sort();

                const newURL = `${window.location.pathname}?${stateSearchParams.toString()}`;
                const oldURL = `${window.location.pathname}?${urlSearchParams.toString()}`;
                if (newURL !== oldURL) {
                    const route = decodeURIComponent(newURL);
                    router.push(route);
                    router.refresh();
                }
            }
        };
    })
);
